<template>
  <div class="single-social-performance-entry">
    <div class="header">
      <div class="info">
        <div class="title">
          {{ value.name }}
        </div>
        <div class="type">
          {{ value.type }}
        </div>
      </div>
      <div class="user">
        <div class="image">
          <img :src="userPhoto" alt="" v-if="false" data-key="user-image" />
          <svg-icon class="icon" name="user-solid" v-else></svg-icon>
        </div>
        <div :class="['post-icon', platform]">
          <svg-icon class="icon" :name="platform"></svg-icon>
        </div>
      </div>
    </div>
    <div class="data">
      <div v-for="(keyStr, index) in keys" :class="[keyStr]" :key="keyStr">
        <div class="title">{{ keyStr }}</div>
        <div class="range" :style="`width: ${rangeWidth(values[index])}px;`"></div>
        <div class="value">{{ values[index] }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { round, reduce, get, isNaN } from 'lodash';
export default {
  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      keys: ['likes', 'comments', 'shares'],
    };
  },
  computed: {
    platform() {
      return this.value.platform;
    },
    rangeWidth() {
      return value => {
        const totalValue = this.totalValue;
        if (!totalValue) return 0;

        const sectionShare = round(value / totalValue, 8);
        return sectionShare * 450;
      };
    },
    totalValue() {
      return reduce(this.values, (prev, data) => (isNaN(data) || !data ? 0 : data) + prev, 0);
    },
    values() {
      return [
        get(this.value, 'reports.likes', 0),
        get(this.value, 'reports.comments', 0),
        get(this.value, 'reports.shares', 0),
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
.single-social-performance-entry {
  display: inline-flex;
  flex-wrap: wrap;
  width: 480px;
  padding: 20px 25px;
  border: 1px solid #e5e5e5;
  border-radius: 12px;
  align-content: flex-start;

  > * {
    display: flex;
    width: 100%;
    align-items: center;
  }

  > .header {
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;

    > .info {
      display: inline-flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 0 20px 0 0;

      > * {
        display: inline-flex;
      }

      > .title {
        color: #000000;
        font-weight: 700;
        font-size: 14px;
        text-transform: capitalize;
      }

      > .type {
        color: #868686;
        font-size: 12px;
        text-transform: capitalize;
        margin: 12px 0 0 0;
      }
    }

    > .user {
      display: inline-flex;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      background-color: #c4c4c4;
      justify-content: center;
      align-items: center;
      position: relative;
      flex-shrink: 0;
      flex-grow: 0;

      > .image {
        display: inline-flex;
        width: 32px;
        height: 32px;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        overflow: hidden;

        > img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        > .icon {
          width: 30px;
          height: 30px;
          fill: #e9e9e9;
        }
      }

      > .post-icon {
        display: inline-flex;
        position: absolute;
        bottom: -12px;
        width: 24px;
        height: 24px;
        color: #ffffff;
        border-radius: 50%;
        justify-content: center;
        align-items: center;

        > .icon {
          width: 14px;
          height: 14px;
          margin: 4px 0 0 0;
        }

        &.facebook {
          background-color: #3b5998;

          > .icon {
            width: 13px;
            height: 13px;
            margin: 3px 0 0 0;
          }
        }

        &.twitter {
          background-color: #3d82b6;
        }

        &.reddit {
          background-color: #bd3737;
        }

        &.linkedin {
          background-color: #46e1c4;
        }

        &.wordpress {
          background-color: #ae7c31;
        }

        &.medium {
          background-color: #9e3192;
        }

        > .icon {
          display: inline-flex;
          transform: scale(0.8);
        }
      }
    }
  }

  > .data {
    margin: 40px 0 0 0;
    flex-wrap: wrap;
    align-content: flex-start;

    > * {
      display: flex;
      width: 100%;
      border-radius: 10px;
      padding: 13px;
      border: 1px solid #e5e5e5;

      &:not(:first-child) {
        margin-top: 18px;
      }

      > * {
        display: inline-flex;
        align-items: center;
        margin: 0;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }

      > .title {
        width: 20%;
        font-size: 14px;
        font-weight: 400;
        color: #000000;
        text-transform: capitalize;
        flex-shrink: 0;
      }

      > .range {
        width: auto;
        border-radius: 20px;
        box-shadow: 0px 1px 20px -5px rgba(0, 0, 0, 0.2);
      }

      > .value {
        width: 3%;
        margin: 0 0 0 auto;
      }

      &.likes {
        > .range {
          background-color: #68db6b;
        }
      }

      &.comments {
        > .range {
          background-color: #93a2f5;
        }
      }

      &.shares {
        > .range {
          background-color: #ff7a00;
        }
      }
    }
  }
}
</style>
