<template>
  <b-dropdown
    v-b-tooltip.hover.bottom
    title="Select accounts"
    ref="dropdown"
    variant="clear"
    class="custom-dropdown filter-sort-wrapper"
    toggle-class="activated"
    boundary="window"
    size="sm"
    right
    no-flip
    no-caret
  >
    <template v-slot:button-content>
      <span class="text"> {{ currentFilter.title }} </span>
      <svg-icon name="chevron-down" />
    </template>
    <ul class="inner">
      <li
        v-for="filter in filters"
        :key="filter.title"
        class="list_item_li"
        :class="{ is_enabled: isEnabled(filter) }"
        @click="enableFilter(filter)"
      >
        <svg-icon v-if="isEnabled(filter)" name="check" />
        <p>{{ filter.title }}</p>
        <p v-if="filter.description" class="description">{{ filter.description }}</p>
      </li>
    </ul>
  </b-dropdown>
</template>

<script>
import { cloneDeep, isObject, get } from 'lodash';

export default {
  props: {
    filters: {
      type: Array,
      required: true,
    },
    value: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
  },
  data() {
    return {
      currentFilter: isObject(this.value) ? this.value : get(this.filters, 0),
    };
  },

  computed: {
    isEnabled() {
      return filter => {
        return filter === this.currentFilter;
      };
    },
  },

  methods: {
    enableFilter(filter) {
      this.currentFilter = filter;
      this.$emit('input', cloneDeep(this.currentFilter));
      this.$refs.dropdown.hide(true);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '~@/scss/variables';

.activated {
  background-color: $gray-200;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.7rem;
  font-weight: 500;

  background: #f5f9fc;
  min-width: 110px;

  .icon {
    width: 0.5rem;
    height: 0.5rem;
    margin-top: 0;
  }
}

.filter-sort-wrapper {
  ::v-deep {
    .dropdown-toggle {
      display: flex;
      flex-wrap: nowrap;
      border: 1px solid #e5e5e5;
      background-color: #f4f4f4;
      padding: 8px 20px;
      border-radius: 10px;
      align-items: center;
      justify-content: flex-start;
      max-width: 300px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;

      &:hover {
        background-color: #e2e2e2;
      }

      > * {
        padding: 0;

        &:not(:first-child) {
          margin: 0 0 0 10px;
        }
      }

      > .text {
        flex-grow: 1;
        flex-shrink: 1;
        flex-wrap: wrap;
        display: inline-flex;
        align-content: flex-start;
        justify-content: flex-start;
        max-width: 50px;
        white-space: normal;
        word-wrap: break-word;
      }

      > .icon {
        margin: 0 0 0 18px;
      }
    }

    .inner {
      max-height: 300px;
      overflow: auto;
      padding: 10px 0;
    }

    .dropdown-menu {
      min-width: 300px;
      max-width: 350px;

      &:after,
      &:before {
        display: none;
      }

      li {
        position: relative;

        &.list_item_li {
          padding: 7px 20px;
          padding-right: 30px;
          display: block;
          color: #3a4557;
          font-size: 14px;
          cursor: pointer;

          .icon {
            position: absolute;
            right: 12px;
            color: #1ecb7b;
            top: 50%;
            transform: translateY(-50%);
          }

          &:hover {
            background: #f5f9fc;
          }
        }
      }
    }

    .description {
      font-size: 12px;
      color: #989eb5;
    }
  }
}
</style>
