<template>
  <div class="social-performance-entry">
    <Content
      class="wrapper"
      title="Social performance"
      description="Key metrics of the Social Profiles in the selected time period."
      :has-content-padding="false"
    >
      <div class="filters">
        <div class="social-accounts">
          <router-link
            :class="['account', { disabled: account.disabled }]"
            v-for="account in accounts"
            :key="account.title"
            :to="getSocialAccountFilterRoute(account)"
            exact-active-class="active"
          >
            {{ account.title }}
          </router-link>
        </div>
        <SortFilter :filters="filters" v-model="currentFilter"></SortFilter>
      </div>
    </Content>
    <div v-if="$apollo.queries.accountList.loading" class="bg-white shadow-sm rounded text-center py-5 loader">
      <spinner size="4" />
    </div>
    <div
      class="list"
      v-else-if="filteredAccountList && filteredAccountList.length > 0 && !$apollo.queries.accountList.loading"
    >
      <SingleSocialPerformance v-for="account in filteredAccountList" :key="account.id" :value="account" />
    </div>
    <div
      v-else-if="filteredAccountList && filteredAccountList.length < 1 && !$apollo.queries.accountList.loading"
      class="empty"
    >
      <div class="bg-white rounded text-center p-5">
        <img class="pt-3" width="300" src="@/assets/images/lost.svg" alt="" />
        <h4 class="pt-5">No social account here yet</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { get } from 'lodash';
import Content from '~/components/Reports/partials/Content';
import SortFilter from '@/components/Reports/partials/SortFilter';
import SingleSocialPerformance from '@/components/Reports/overview/partials/SingleSocialPerformance';
import { REPORT_QUERY } from '~/graphql/queries';

const workspaceNamespace = createNamespacedHelpers('workspace');

export default {
  data() {
    return {
      filters: [
        {
          title: 'Top 5',
        },
        {
          title: 'Top 10',
        },
        {
          title: 'Top 20',
        },
        {
          title: 'Bottom 5',
        },
        {
          title: 'Bottom 10',
        },
        {
          title: 'Bottom 20',
        },
      ],
      currentFilter: null,
      accounts: [
        { title: 'overall' },
        { title: 'facebook' },
        { title: 'twitter' },
        { title: 'linkedin', disabled: true },
      ],
      accountList: [],
    };
  },
  components: {
    Content,
    SortFilter,
    SingleSocialPerformance,
  },
  computed: {
    getSocialAccountFilterRoute() {
      return account => {
        return { ...this.$route, query: { ...this.$route.query, social: account.title } };
      };
    },
    currentSocialAccount() {
      return get(this.$route, 'query.social');
    },
    filteredAccountList() {
      const currentSocialAccount = this.currentSocialAccount;
      if (currentSocialAccount === 'overall') return this.accountList;
      return this.accountList.filter(account => account.platform === currentSocialAccount);
    },
    getLimit() {
      const filter = this.getFilter;
      if (!filter) return 15;

      const limit = parseInt(filter[1], 10);
      if (isNaN(limit)) return 15;

      return limit;
    },
    getFilter() {
      const filter = get(this.currentFilter, 'title');
      if (!filter) return;

      return filter.split(' ');
    },
    getDirection() {
      const filter = this.getFilter;
      if (!filter) return 'desc';

      const direction = String(filter[0]).toLowerCase();
      if (direction === 'bottom') return 'asc';

      return 'desc';
    },
    ...workspaceNamespace.mapState({
      workspace: state => state.current,
    }),
  },

  created() {
    if (!this.currentSocialAccount) {
      this.$router.push(this.getSocialAccountFilterRoute({ title: 'overall' }));
    }
  },

  apollo: {
    accountList: {
      query: REPORT_QUERY,
      variables() {
        return {
          name: 'overview.social-performance',
          workspace: this.workspace.id,
          limit: this.getLimit,
          direction: this.getDirection,
        };
      },
      update(data) {
        const accountList = get(data, 'reports.accounts');
        return accountList;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.social-performance-entry {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-content: flex-start;

  ::v-deep {
    > .wrapper {
      width: 100%;
      padding-bottom: 0;

      > .content {
        margin: 30px 0 0 0;
        flex-wrap: wrap;
        align-content: space-between;
        justify-content: space-between;
        align-items: stretch;
        flex-grow: 1;

        > .filters {
          display: flex;
          width: 100%;
          padding: 0 25px;
          flex-wrap: nowrap;

          > * {
            display: inline-flex;
            flex-wrap: nowrap;
            justify-content: flex-start;
          }

          > .social-accounts {
            margin: 0 30px 0 0;
            max-width: 85%;
            justify-content: space-between;

            > .account {
              position: relative;
              padding: 10px 30px 12px;
              font-size: 18px;
              text-transform: capitalize;
              color: #868686 !important;
              text-decoration: initial;

              &:not(:nth-of-type(1)) {
                margin: 0 0 0 30px;
              }

              &::after {
                display: inline-flex;
                position: absolute;
                flex-grow: 1;
                content: '';
                height: 4px;
                width: 100%;
                bottom: 0;
                left: 0;
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
                background-color: transparent;
                transition: all 0.3s ease-in-out;
              }

              &:not(.disabled) {
                &:not(.active):hover,
                &.active {
                  &::after {
                    background-color: #93a2f9;
                  }
                }
              }

              &.disabled {
                pointer-events: none;
              }
            }
          }

          > .filter-sort-wrapper {
            margin: 0 0 0 auto;
          }
        }
      }
    }

    > .list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
      align-content: flex-start;
      padding: 0 25px 25px;
      margin: 35px 0 0 0;
      background-color: #ffffff;
      border-radius: 12px;
      justify-content: center;

      > .single-social-performance-entry {
        width: 420px;
        margin: 30px 40px 0 0;
      }
    }

    > .loader,
    > .empty {
      width: 100%;
      min-height: 500px;
      justify-content: center;
      align-items: center;
      display: flex;
      background-color: #ffffff;
      box-shadow: 3px 3px 4px 0 rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
